import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { AclRole } from "@/shared/plugin/acl/acl.type";

export const workplaceRoutes: RouteRecordRaw = {
  path: "/accountant-workplace",
  name: RouteType.Workplace,
  redirect: {
    name: RouteType.WorkplaceDashboard,
  },
  meta: {
    access: [
      AclRole.Accountant,
      AclRole.Admin,
      AclRole.Support,
      AclRole.HrPersonnel,
      AclRole.DmsAdmin,
      AclRole.DmsListAdmin,
    ],
  },
  component: () => import("@/views/workplace/workplace.vue"),
  children: [
    {
      path: "",
      name: RouteType.WorkplaceDashboard,
      component: () => import("@/views/workplace/workplace-dashboard/workplace-dashboard.vue"),
      meta: {
        title: "Рабочее место",
      },
    },
    {
      path: "report-for-tax",
      name: RouteType.WorkplaceReportForTax,
      component: () => import("@/views/workplace/report-for-tax/report-for-tax.vue"),
      meta: {
        breadcrumbs: [
          {
            label: "Рабочее место",
            route: { name: RouteType.Workplace },
          },
        ],
      },
    },
    {
      path: "eds-registry",
      name: RouteType.WorkplaceEDSRegistry,
      component: () => import("@/views/workplace/eds-registry/eds-registry.vue"),
      meta: {
        title: "Реестр ЭЦП",
        breadcrumbs: [
          {
            label: "Рабочее место",
            route: { name: RouteType.Workplace },
          },
        ],
      },
    },
    {
      path: "dms-requests",
      name: RouteType.WorkplaceDMSRequests,
      component: () => import("@/views/workplace/dms-request-list/dms-request-list.vue"),
      meta: {
        title: "Заявки на ДМС",
        breadcrumbs: [
          {
            label: "Рабочее место",
            route: { name: RouteType.Workplace },
          },
        ],
        access: [AclRole.Admin, AclRole.Support, AclRole.HrPersonnel, AclRole.DmsAdmin],
      },
    },
    {
      path: "dms-program",
      name: RouteType.WorkplaceDMSProgram,
      redirect: {
        name: RouteType.WorkplaceDMSProgramList,
      },
      children: [
        {
          path: "",
          name: RouteType.WorkplaceDMSProgramList,
          component: () => import("@/views/workplace/dms-program/dms-program-list/dms-program-list.vue"),
          meta: {
            title: "Программы ДМС",
            breadcrumbs: [
              {
                label: "Рабочее место",
                route: { name: RouteType.Workplace },
              },
            ],
            access: [AclRole.Admin, AclRole.Support, AclRole.HrPersonnel, AclRole.DmsListAdmin],
          },
        },
        {
          path: "create",
          name: RouteType.WorkplaceDMSProgramCreate,
          component: () => import("@/views/workplace/dms-program/dms-program-create/dms-program-create.vue"),
          meta: {
            title: "Добавление программы ДМС",
            breadcrumbs: [
              {
                label: "Рабочее место",
                route: { name: RouteType.Workplace },
              },
              {
                label: "Программы ДМС",
                route: { name: RouteType.WorkplaceDMSProgramList },
              },
            ],
            access: [AclRole.Admin, AclRole.Support, AclRole.DmsListAdmin],
          },
        },
        {
          path: "edit/:id",
          name: RouteType.WorkplaceDMSProgramEdit,
          component: () => import("@/views/workplace/dms-program/dms-program-edit/dms-program-edit.vue"),
          meta: {
            title: "Редактирование программы ДМС",
            breadcrumbs: [
              {
                label: "Рабочее место",
                route: { name: RouteType.Workplace },
              },
              {
                label: "Программы ДМС",
                route: { name: RouteType.WorkplaceDMSProgramList },
              },
            ],
            access: [AclRole.Admin, AclRole.Support, AclRole.DmsListAdmin],
          },
        },
      ],
    },
  ],
};
