import { Vmi } from "@finapp/proto/pkg-ts/vmi/messages";

export enum DMSRequestStatus {
  Draft = "draft",
  NeedChange = "need_change",
  RevokeForChanging = "revoke_for_changing",
  AwaitingApprovalHrPersonnel = "awaiting_approval_hr_personnel",
  Approved = "approved",
  Canceled = "canceled",
  ProcessingError1c = "processing_error_1c",
  AwaitingSignEmployee = "awaiting_sign_employee",
  DigitallySigned = "digitally_signed",
  PhysicallySigned = "physically_signed",
  ProcessingErrorSign = "processing_error_sign",
}

export interface DMSRequest extends Vmi {
  status: DMSRequestStatus;
}
