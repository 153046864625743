import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { RpcTransport, ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { Timestamp } from "@finapp/proto/pkg-ts/google/protobuf/timestamp";
import { RpcInterceptor } from "@protobuf-ts/runtime-rpc/build/types/rpc-interceptor";
import { useAuthState } from "@/store/modules/auth";
import { computed, unref } from "vue";
import dayjs from "dayjs";
import { useLocales } from "@/shared/composables/use-locales";
import { FeatureFlag, useFeature } from "@/shared/composables/use-feature/use-feature";
import { grpcMockInterceptor } from "@/shared/composables/use-grpc-mock/use-grpc-mock";

const baseUrl = process.env.NODE_ENV === "production" ? "/" : "/api/";
const { state: authState } = useAuthState();
const token = computed(() => authState.token);
const rpcMetaAuthKey = "authorization";
const rpcMetaLocale = "locale";
const defaultDate: Timestamp = { seconds: "0", nanos: 0 };
const { getLocale } = useLocales();
const { featureIsEnable } = useFeature();

const interceptors: RpcInterceptor[] = [];

const transportInterceptors: RpcInterceptor = {
  interceptUnary: (next, method, input, options) => {
    if (!options.meta) {
      options.meta = {};
    }
    if (token.value) {
      options.meta[rpcMetaAuthKey] = `Bearer ${token.value}`;
    }
    options.meta[rpcMetaLocale] = getLocale();
    return next(method, input, options);
  },
  interceptServerStreaming: (next, method, input, options) => {
    if (!options.meta) {
      options.meta = {};
    }
    if (token.value) {
      options.meta[rpcMetaAuthKey] = `Bearer ${token.value}`;
    }
    options.meta[rpcMetaLocale] = getLocale();
    return next(method, input, options);
  },
};
interceptors.push(transportInterceptors);

if (process.env.VUE_APP_GRPC_LOGGER === "true" && featureIsEnable(FeatureFlag.GRPC_Logger)) {
  const loggerInterceptors: RpcInterceptor = {
    interceptUnary: (next, method, input, options) => {
      const result = next(method, input, options);
      const request = unref(result.request);
      const serviceName = result.method.service.typeName;
      const methodName = result.method.name;
      /* eslint-disable no-console */
      void (async () => {
        try {
          const response = await result.response;
          console.groupCollapsed(`✅ GRPC: %c%s.%c%s`, "color: blue", serviceName, "color: green", methodName);

          console.group("Request:");
          console.log(JSON.stringify(request, null, 2));
          console.log(request);
          console.groupEnd();

          console.group("Response:");
          console.log(JSON.stringify(response, null, 2));
          console.log(response);
          console.groupEnd();

          console.groupEnd();
        } catch (e) {
          console.groupCollapsed(`⚠️ GRPC: %c%s.%c%s`, "color: orange", serviceName, "color: red", methodName);
          console.group("Request:");
          console.log(JSON.stringify(request, null, 2));
          console.error(e);
          console.groupEnd();
        }
      })();
      /* eslint-enable */
      return result;
    },
  };

  interceptors.push(loggerInterceptors);
}

if (process.env.NODE_ENV === "development") {
  interceptors.push(grpcMockInterceptor);
}

const transport = new GrpcWebFetchTransport({
  format: "binary",
  baseUrl,
  interceptors,
});

export function client<C>(clientClass: new (transport: RpcTransport) => C): C {
  return new clientClass(transport);
}

export function getTimeStampFromDate(date?: number | string | Date, timezone = "Greenwich"): Timestamp {
  const myDate = dayjs(date).tz(timezone, true);
  return Timestamp.fromDate(myDate.toDate());
}

export function getDateFromTimeStamp(timeStamp: Timestamp = defaultDate, timeZone?: string): Date {
  const date = Timestamp.toDate(timeStamp);
  return timeZone ? new Date(date.toLocaleString("en-US", { timeZone })) : date;
}

export const getAttachmentUrl = (attachmentId: string): string => {
  const host = `${window.location.protocol}//${window.location.host}`;
  return `${host}${baseUrl}finapp/v1/attachments/${attachmentId}/${token.value}`;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const streamToPromise = async <I extends object, O extends object>(
  streamingCall: ServerStreamingCall<I, O>,
): Promise<O> => {
  return new Promise<O>((resolve, reject) => {
    const response = streamingCall.responses;
    response.onMessage((resp) => resolve(resp));
    response.onError((err) => reject(err));
  });
};
