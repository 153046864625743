import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5e629fc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-message" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "error-message-text-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElCollapseItem = _resolveComponent("ElCollapseItem")!
  const _component_ElCollapse = _resolveComponent("ElCollapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.errorCode)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Код ошибки: " + _toDisplayString(_ctx.errorCode), 1))
      : _createCommentVNode("", true),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Сообщение: " + _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ElCollapse, { accordion: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ElCollapseItem, { title: "Подробнее" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.error), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}