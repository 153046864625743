import { createRouter, createWebHistory, LocationQuery, RouteRecordRaw } from "vue-router";
import { RouteType } from "@/router/route.type";
import { myCardsRoutes } from "@/router/routes/my-cards.routes";
import { absencesRoutes } from "@/router/routes/absences.routes";
import { advanceReportsRoutes } from "@/router/routes/advance-reports.routes";
import { subReportsRoutes } from "@/router/routes/sub-report.routes";
import { backofficeRoutes } from "@/router/routes/backoffice.routes";
import { subordinatesRequestsRoutes } from "@/router/routes/subordinates-requests.routes";
import { myRequestsRoutes } from "@/router/routes/my-requests.routes";
import { officeManagerRequestsRoutes } from "@/router/routes/office-manager-requests.routes";
import { workplaceRoutes } from "@/router/routes/workplace.routes";
import { authHook } from "@/router/hooks/auth.router-hook";
import { aclHook } from "@/router/hooks/acl.router-hook";
import { locationsHistoryHook } from "@/router/hooks/locations-history.router-hook";
import { defineAsyncComponent } from "vue";
import { reportsRoutes } from "@/router/routes/reports.routes";
import qs from "qs";
import { redirectHook } from "@/router/hooks/redirect.router-hook";
import { dmsRequestRoutes } from "@/router/routes/dms-request.routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteType.Home,
    component: () => import("@/views/home-page/home-page.vue"),
    meta: {
      title: "Финансовый помощник",
    },
  },
  subordinatesRequestsRoutes,
  myRequestsRoutes,
  myCardsRoutes,
  absencesRoutes,
  advanceReportsRoutes,
  subReportsRoutes,
  officeManagerRequestsRoutes,
  backofficeRoutes,
  workplaceRoutes,
  reportsRoutes,
  dmsRequestRoutes,
  {
    path: "/notifications",
    name: RouteType.Notifications,
    component: () => import("@/views/notifications/notifications.vue"),
    meta: {
      title: "Уведомления",
    },
  },
  {
    path: "/settings",
    name: RouteType.Settings,
    component: () => import("@/views/settings/settings.vue"),
    meta: {
      title: "Настройки",
    },
  },
  {
    path: "/auth-callback",
    name: RouteType.AuthCallback,
    props: (route) => ({ code: route.query.code }),
    component: () => import("@/views/auth-callback/auth-callback.vue"),
  },
  {
    path: "/privacy",
    name: RouteType.Privacy,
    component: () => import("@/views/dev-page/dev-page.vue"),
    meta: {
      title: "Политика конфиденциальности",
    },
  },
  {
    path: "/widget",
    name: RouteType.Widget,
    component: () => import("@/views/widget/widget.vue"),
    meta: {
      layout: defineAsyncComponent(() => import("@/layouts/widget/widget-layout.vue")),
    },
  },
  {
    path: "/error/:code?",
    name: RouteType.Error,
    component: () => import("@/views/error-page/error-page.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: {
      name: RouteType.Error,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
    return { top: 0 };
  },
  parseQuery: (search) => {
    return qs.parse(search) as LocationQuery;
  },
  stringifyQuery: qs.stringify,
  routes,
});

router.beforeEach(authHook);

router.beforeEach(redirectHook);

router.beforeEach(aclHook);

router.afterEach(locationsHistoryHook);

export default router;
