import { LocationQuery, RouteParams, RouteRecordName } from "vue-router";
import { AclRole } from "@/shared/plugin/acl/acl.type";
import { Component } from "vue";

export interface LocationAsName {
  name: RouteRecordName;
  params?: RouteParams;
  query?: LocationQuery;
}

export type RouterBreadcrumb = {
  label: unknown;
  route: LocationAsName;
};

declare module "vue-router" {
  interface RouteMeta {
    title?: string;
    access?: AclRole[];
    breadcrumbs?: RouterBreadcrumb[];
    excludeFromLocationsHistory?: boolean;
    layout?: Component;
  }
}

export enum RouteType {
  AuthCallback = "auth-callback",
  Backoffice = "backoffice",
  BackofficeDashboard = "backoffice-dashboard",
  BackofficeSimpleDict = "backoffice-simple-dict",
  BackofficeEkkDict = "backoffice-ekk-dict",
  BackofficeExpenseCostDict = "backoffice-expense-cost-dict",
  BackofficeCorrespondenceDict = "backoffice-correspondence-dict",
  BackofficeProcessingError = "processing-error",
  BackofficeUserRoutingOptions = "backoffice-user-routing-options",
  BackofficeUserRoutingOptionsList = "backoffice-user-routing-options-list",
  BackofficeUserRoutingOptionsAddRow = "backoffice-user-routing-options-add-row",
  BackofficeUserRoutingOptionsEditRow = "backoffice-user-routing-options-edit-row",
  BackofficeUserOptions = "backoffice-user-options",
  BackofficeUserOptionsList = "backoffice-user-routing-list",
  BackofficeUserOptionsAddRow = "backoffice-user-routing-add",
  BackofficeUserOptionsEditRow = "backoffice-user-routing-edit",
  BackofficeEkkToRoutingKey = "backoffice-ekk-to-routing-key",
  BackofficeEkkToOrg = "backoffice-ekk-to-org",
  BackofficeUsersInfo = "backoffice-users-info",
  Home = "home",
  Privacy = "privacy",
  Requests = "requests",
  RequestsList = "requests-list",
  MyCards = "my-cards",
  MyCardsList = "my-cards-list",
  MyCardsCreate = "my-cards-create",
  MyCardsFillDetails = "my-cards-fill-details",
  MyCardsChangeLimit = "my-cards-change-limit",
  MyCardsRenew = "my-cards-renew",
  MyCardsOrder = "my-cards-order",
  MyCardsOrderComments = "my-cards-order-comments",
  MyCardsSuccess = "my-cards-success",
  Absences = "absences",
  AbsencesList = "absences-list",
  AbsenceId = "absence-id",
  AbsenceComment = "absence-comment",
  AbsenceRequestCreate = "absence-request-create",
  AbsenceRequestEdit = "absence-request-edit",
  AdvanceReports = "advance-reports",
  AdvanceReportsList = "advance-reports-list",
  AdvanceReportsItem = "advance-reports-item",
  AdvanceReportsComment = "advance-reports-item-comment",
  AdvanceReportsEdit = "advance-reports-edit",
  AdvanceReportsCreate = "advance-reports-create",
  SubReport = "sub-report",
  SubReportList = "sub-report-list",
  SubReportCreate = "sub-report-create",
  SubReportView = "sub-report-view",
  SubReportComments = "sub-report-comments",
  SubReportEdit = "sub-report-edit",
  OfficeManagerRequests = "office-manager-requests",
  OfficeManagerRequestsCreate = "office-manager-request-create",
  Reports = "reports",
  ReportsDashboard = "reports-dashboard",
  ReportsVacationDays = "vacation-days-report",
  Notifications = "notifications",
  Settings = "settings",
  Subordinates = "subordinates",
  SubordinatesRequestsList = "subordinates-requests-list",
  Error = "error",
  Workplace = "accountant-workplace",
  WorkplaceDashboard = "accountant-workplace-dashboard",
  WorkplaceReportForTax = "accountant-workplace-report-for-tax",
  WorkplaceEDSRegistry = "accountant-workplace-eds-registry",
  WorkplaceDMSRequests = "workplace-dms-requests",
  WorkplaceDMSProgram = "workplace-dms-program",
  WorkplaceDMSProgramList = "workplace-dms-program-list",
  WorkplaceDMSProgramCreate = "workplace-dms-program-create",
  WorkplaceDMSProgramEdit = "workplace-dms-program-edit",
  Widget = "widget",
  DMSRequest = "dms-request",
  DMSRequestCreate = "dms-request-create",
  DMSRequestView = "dms-request-view",
  DMSRequestComments = "dms-request-comments",
  DMSRequestEdit = "dms-request-edit",
}

export enum RouterAnchor {
  AdvanceReportsItemExpenses = "#expenses",
}
