import { AclRole, DmsAclRules, SetRule } from "@/shared/plugin/acl/acl.type";
import { Employee } from "@finapp/proto/pkg-ts/common/common";
import { DMSRequest, DMSRequestStatus } from "@/store/modules/dms-request/dms-request.types";
import { useAcl } from "vue-simple-acl/src";
import { VmiBeneficiaryType } from "@finapp/proto/pkg-ts/vmi/messages";

const { role, anyRole } = useAcl();

export function setDmsRequestAclRules(setRule: SetRule) {
  // Редактирование
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.Edit, (user, dmsRequest) => {
    const isCreator = user.portalCode === dmsRequest.creator?.portalCode;
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const resolvedStatus = [
      DMSRequestStatus.Draft,
      DMSRequestStatus.RevokeForChanging,
      DMSRequestStatus.NeedChange,
    ].includes(dmsRequest.status);

    return (isCreator || isAdmin) && resolvedStatus;
  });
  // Отозвать для редактирования
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.RevokeForChange, (user, dmsRequest) => {
    const isCreator = user.portalCode === dmsRequest.creator?.portalCode;
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const resolvedStatus = [DMSRequestStatus.AwaitingApprovalHrPersonnel].includes(dmsRequest.status);
    return (isCreator || isAdmin) && resolvedStatus;
  });
  // Согласование
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.Approve, (user, dmsRequest) => {
    const isApprover = role(AclRole.HrPersonnel);
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const resolvedStatus = [DMSRequestStatus.AwaitingApprovalHrPersonnel].includes(dmsRequest.status);
    return (isApprover || isAdmin) && resolvedStatus;
  });
  // Отправка на доработку
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.Improve, (user, dmsRequest) => {
    const isApprover = role(AclRole.HrPersonnel);
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const resolvedStatus = [DMSRequestStatus.AwaitingApprovalHrPersonnel].includes(dmsRequest.status);
    return (isApprover || isAdmin) && resolvedStatus;
  });
  // Отмена
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.Cancellation, (user, dmsRequest) => {
    const isApprover = role(AclRole.HrPersonnel);
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const resolvedStatus = [DMSRequestStatus.AwaitingApprovalHrPersonnel].includes(dmsRequest.status);
    return (isApprover || isAdmin) && resolvedStatus;
  });
  // Просмотр разрешения на обработку ПД
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.ViewProcessingPd, (user, dmsRequest) => {
    const employeeRole = user.portalCode === dmsRequest.creator?.portalCode;
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const personnelRole = anyRole([AclRole.HrPersonnel]);
    const resolvedStatus = [
      DMSRequestStatus.AwaitingSignEmployee,
      DMSRequestStatus.DigitallySigned,
      DMSRequestStatus.PhysicallySigned,
    ].includes(dmsRequest.status);
    return ((employeeRole || personnelRole) && resolvedStatus) || isAdmin;
  });
  // Подписание разрешения на обработку ПД
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.SignProcessingPd, (user, dmsRequest) => {
    const employeeRole = user.portalCode === dmsRequest.creator?.portalCode;
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const resolvedStatus = [DMSRequestStatus.AwaitingSignEmployee].includes(dmsRequest.status);
    const employeeDS = user.hasDigitalSignature;
    const beneficiaryType =
      dmsRequest.beneficiary !== undefined &&
      (dmsRequest.beneficiary.type === VmiBeneficiaryType.EMPLOYEE ||
        dmsRequest.beneficiary.type === VmiBeneficiaryType.RELATIVE_CHILD);

    return (employeeRole || isAdmin) && resolvedStatus && employeeDS && beneficiaryType;
  });
  // Просмотр заявления на присоединение к ДМС
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.ViewApplication, (user, dmsRequest) => {
    const employeeRole = user.portalCode === dmsRequest.creator?.portalCode;
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const personnelRole = anyRole([AclRole.HrPersonnel]);
    const resolvedStatus = [
      DMSRequestStatus.AwaitingSignEmployee,
      DMSRequestStatus.DigitallySigned,
      DMSRequestStatus.PhysicallySigned,
    ].includes(dmsRequest.status);
    return ((employeeRole || personnelRole) && resolvedStatus) || isAdmin;
  });
  // Подписание заявления на присоединение к ДМС
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(DmsAclRules.SignApplication, (user, dmsRequest) => {
    const employeeRole = user.portalCode === dmsRequest.creator?.portalCode;
    const isAdmin = anyRole([AclRole.Admin, AclRole.Support]);
    const resolvedStatus = [DMSRequestStatus.AwaitingSignEmployee].includes(dmsRequest.status);
    const employeeDS = user.hasDigitalSignature;
    return (employeeRole || isAdmin) && resolvedStatus && employeeDS;
  });
  // Пометить заявку на ДМС как подписанную на бумаге
  setRule<(user: Employee, dmsRequest: DMSRequest) => void>(
    DmsAclRules.MarkDmsRequestSignedByHand,
    (user, dmsRequest) => {
      const userRole = anyRole([AclRole.HrPersonnel, AclRole.Admin, AclRole.Support]);
      const resolvedStatus = [DMSRequestStatus.AwaitingSignEmployee].includes(dmsRequest.status);
      return userRole && resolvedStatus;
    },
  );
  // Выгрузка заявок для СК
  setRule<(user: Employee) => void>(DmsAclRules.UploadVmiArchive, () => {
    const userRole = anyRole([AclRole.DmsAdmin, AclRole.Admin, AclRole.Support]);
    return userRole;
  });
}
