import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { RequestWithCommentsView } from "@/components/request/request-with-comments/request-with-comments.types";

export const dmsRequestRoutes: RouteRecordRaw = {
  path: "/dms-request",
  name: RouteType.DMSRequest,
  meta: {
    title: "",
  },
  children: [
    {
      path: "create",
      name: RouteType.DMSRequestCreate,
      component: () => import("@/views/dms-request/dms-request-create/dms-request-create.vue"),
      meta: {
        title: "Заявка на ДМС",
      },
    },
    {
      path: "edit/:id",
      name: RouteType.DMSRequestEdit,
      component: () => import("@/views/dms-request/dms-request-edit/dms-request-edit.vue"),
      meta: {
        title: "Заявка на ДМС",
      },
    },
    {
      path: ":id/:action(\\approve|improve|cancellation)?",
      name: RouteType.DMSRequestView,
      component: () => import("@/views/dms-request/dms-request-view/dms-request-view.vue"),
      meta: {
        title: "Заявка на ДМС",
      },
      props: {
        visibleView: RequestWithCommentsView.View,
      },
    },
    {
      path: ":id/comments",
      name: RouteType.DMSRequestComments,
      component: () => import("@/views/dms-request/dms-request-view/dms-request-view.vue"),
      meta: {
        title: "Заявка на ДМС",
        excludeFromLocationsHistory: true,
      },
      props: {
        visibleView: RequestWithCommentsView.Comments,
      },
    },
  ],
};
